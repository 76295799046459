const requestApi = require("@/lib/request");
// 用户接口
module.exports = {
    //用户列表
    vipList(data) {
        return new Promise((resolve, reject) => {
            requestApi.userRequest('/api/vip/vip-list', data).then(res => {
                resolve(res);
            }).catch(res => {
                reject(res);
            });
        });
    }
}